import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import Slider1 from '../../../images/slider_image_1.png'
import Slider2 from '../../../images/slider_image_2_1.png'
// import Slider3 from '../../../images/slider_image_3.png'
import Slider4 from '../../../images/slider_image_4.png'

export default function RewardsRecognitionSlider() {
    return (
        <section className='w-full py-10' id='RewardsAndRecognition'>
            <div className='container  max-w-7xl'>
                <div className='text-center mb-16'>
                    <h1 className='ga-subheading'>Rewards &amp; Recognition</h1>
                    {/* <p className='leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500 text-[26px] mt-[15px]'>Awards for Top 3 Winners</p> */}
                </div>
                <div className='mt-6'>
                    <Swiper navigation={true} loop={false} modules={[Navigation]} loopFillGroupWithBlank={true} className="image-slider">
                        {/* <SwiperSlide>
                            <img src={Slider1} alt='Slider1' className='mx-auto max-w-[95%]' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={Slider2} alt='Slider1' className='mx-auto max-w-[95%]' />
                        </SwiperSlide> */}
                        {/* <SwiperSlide>
                            <img src={Slider3} alt='Slider1' className='mx-auto' />
                        </SwiperSlide> */}
                        <SwiperSlide>
                            <img src={Slider4} alt='Slider4' className='mx-auto max-w-[95%]' />
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </section>
    );
}
