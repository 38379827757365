import React from 'react';

import Logo from '../../images/footer_logo.svg'

export default function Footer() {
    return <footer className='mt-10' >
        <div className='bg-[#FAFAFA] w-full py-12'>
            <div className='xl:container px-4'>
                <div className='grid grid-cols-1 md:grid-cols-3 space-y-5 md:space-y-0'>
                    <div className='col-span-1 md:col-span-2'>
                        <div>
                            <img src={Logo} />
                            <p className='max-w-sm mt-8'>To understand the utilization of Genomics in personalizing cancer treatment by providing a detailed overview of Molecular Biology techniques & its application in Oncology Clinical Practice.</p>

                            <div className='flex space-x-7 mt-12'>

                                <a href='https://www.linkedin.com/company/4basecare/' className='w-6 h-6'><svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.001 26">
                                    <defs>
                                        <clipPath id="a">
                                            <path fill="none" d="M0 0h26.001v26H0z" data-name="Rectangle 1163" />
                                        </clipPath>
                                    </defs>
                                    <g fill="#2b528e" clipPath="url(#a)" data-name="Group 5127">
                                        <path d="M26 16.373v9.233a.39.39 0 0 1-.391.391h-5.046a.4.4 0 0 1-.4-.391V17.42a7.194 7.194 0 0 0-.407-2.807 2.068 2.068 0 0 0-2.091-1.27 2.315 2.315 0 0 0-2.238 1.132 6.109 6.109 0 0 0-.5 2.8v8.331a.4.4 0 0 1-.391.391H9.635a.4.4 0 0 1-.391-.391V8.797a.4.4 0 0 1 .391-.391h4.7a.4.4 0 0 1 .391.391v1.188a4.758 4.758 0 0 1 1.382-.944 7.662 7.662 0 0 1 3.239-.635 6.672 6.672 0 0 1 4.794 1.5 6.369 6.369 0 0 1 1.732 4.011c.049.367.082.765.1 1.188s.032.846.032 1.27" data-name="Path 3955" />
                                        <path d="M6.674 8.796v16.812a.4.4 0 0 1-.4.391H.4a.4.4 0 0 1-.4-.391V8.796a.4.4 0 0 1 .4-.391h5.875a.4.4 0 0 1 .4.391" data-name="Path 3956" />
                                        <path d="M6.674 3.336a3.354 3.354 0 0 1-.952 2.336L5.7 5.7a3.341 3.341 0 1 1 .977-2.36" data-name="Path 3957" />
                                    </g>
                                </svg>
                                </a>
                                <a href='https://twitter.com/4baseCare' className='w-6 h-6'>
                                    <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.077 22">
                                        <path fill="#2b528e" d="M27.077 2.6a11.574 11.574 0 0 1-3.2.877A5.52 5.52 0 0 0 26.319.409a11.094 11.094 0 0 1-3.52 1.344 5.551 5.551 0 0 0-9.6 3.8 5.716 5.716 0 0 0 .129 1.266A15.712 15.712 0 0 1 1.885 1.012a5.552 5.552 0 0 0 1.706 7.419 5.482 5.482 0 0 1-2.508-.684v.061a5.577 5.577 0 0 0 4.447 5.455 5.54 5.54 0 0 1-1.455.183 4.908 4.908 0 0 1-1.051-.095 5.6 5.6 0 0 0 5.187 3.867 11.154 11.154 0 0 1-6.883 2.368A10.4 10.4 0 0 1 0 19.509 15.628 15.628 0 0 0 8.516 22a15.69 15.69 0 0 0 15.8-15.8c0-.245-.008-.482-.02-.718A11.074 11.074 0 0 0 27.077 2.6Z" data-name="twitter (1)" />
                                    </svg>

                                </a>
                                <a href='https://www.facebook.com/4basecare' className='w-6 h-6'>
                                    <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.134 26">
                                        <path fill="#2b528e" d="M.418 13.848h2.85v11.733a.419.419 0 0 0 .419.419h4.831a.419.419 0 0 0 .419-.419V13.9h3.281a.419.419 0 0 0 .417-.371l.5-4.32a.419.419 0 0 0-.416-.467H8.945V6.037c0-.816.439-1.23 1.306-1.23h2.467a.419.419 0 0 0 .419-.419V.422A.419.419 0 0 0 12.718 0H9.162a6.516 6.516 0 0 0-4.261 1.606 4.468 4.468 0 0 0-1.486 3.972v3.166H.418a.419.419 0 0 0-.419.419v4.264a.419.419 0 0 0 .419.421Z" data-name="facebook (1)" />
                                    </svg>

                                </a>

                                <a href='https://www.instagram.com/4basecare_onco_solutions/' className='w-6 h-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#2b528e"><path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z"/></svg>
                                </a>

                                <a href='https://www.youtube.com/channel/UCTxWrSDbho9zHxBxPZ7KK0A' className='w-6 h-6'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#2b528e"><path d="M12.04 3.5c.59 0 7.54.02 9.34.5a3.02 3.02 0 0 1 2.12 2.15C24 8.05 24 12 24 12v.04c0 .43-.03 4.03-.5 5.8A3.02 3.02 0 0 1 21.38 20c-1.76.48-8.45.5-9.3.51h-.17c-.85 0-7.54-.03-9.29-.5A3.02 3.02 0 0 1 .5 17.84c-.42-1.61-.49-4.7-.5-5.6v-.5c.01-.9.08-3.99.5-5.6a3.02 3.02 0 0 1 2.12-2.14c1.8-.49 8.75-.51 9.34-.51zM9.54 8.4v7.18L15.82 12 9.54 8.41z"/></svg>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div id='ContactUs'>
                        <h4 className='font-verdana text-[32px] leading-[50px] font-bold text-[#101010] mb-[32px]'>Contact Us</h4>
                        <div className='flex space-x-6 mb-[32px]'>
                            <div className='w-[26.6px] h-[33.271px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.682" height="33.271" viewBox="0 0 20.682 33.271">
                                    <path id="Path_3947" data-name="Path 3947" d="M16.261,18.15h0V18.9l.212.75.316.534.633.642.528.534.739.428.633.214h1.689l.739-.214.633-.428.739-.534.422-.642.316-.534.211-.75.212-.748-.212-1.069-.211-.75-.316-.534-.422-.642-.739-.534-.633-.428-.739-.214H19.322l-.633.214-.739.428-.528.534-.633.642-.316.534-.212.75Zm4.01,6.311h0L18.9,24.355l-1.161-.428-1.055-.642-1.056-.748-.739-.963L14.363,20.4l-.422-1.177V16.759l.422-1.177.526-1.177.739-.963,1.056-.748,1.055-.642L18.9,11.73l1.372-.212,1.161.212,1.372.322.95.642.95.748.737.963.528,1.177.422,1.177L26.6,18.15l-.211,1.07L25.97,20.4l-.528,1.177-.737.963-.95.748-.95.642-1.372.428Zm0-16.8h0l-2.111.106-1.9.642-1.687.963-1.583,1.177-1.372,1.5-.95,1.925-.528,2.033L9.931,18.15v1.925L10.353,22l.528,2.139.633,1.925L13.2,29.918l1.9,3.53,1.9,3.1L18.583,38.8l1.689,2.141L21.75,38.8l1.794-2.245,1.9-3.1,1.9-3.53,1.583-3.852.739-1.925L29.98,22l.422-1.927.211-1.925L30.4,16.01l-.739-2.033-.95-1.925-1.161-1.5L25.97,9.377l-1.687-.963-1.9-.642Z" transform="translate(-9.931 -7.666)" fill="#2b528e" fillRule="evenodd" />
                                </svg>
                            </div>
                            <div>
                                <h5 className='font-montserrat text-[15px] leading-[18px] font-bold'>Address :</h5>
                                <p className='font-muli text-[13px] leading-[18px] font-light'>IBAB, Helix Biotech Park, Electronic City Phase-1,
                                    Bangalore 560100, Karnataka, India</p>
                            </div>
                        </div>
                        <div className='flex space-x-6 mb-[32px]'>
                            <div className='w-[26.6px] h-[26.603px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.6" height="26.603" viewBox="0 0 26.6 26.603">
                                    <g id="Group_5879" data-name="Group 5879" transform="translate(-929.192 -9794.441)">
                                        <path id="Path_3949" data-name="Path 3949" d="M31.969,81.4l-.132-.271-.179-.249-.217-.247-1.567-1.568-1.581-1.575-.241-.209-.247-.171-.265-.124-.255-.094-.263-.04h-.273l-.255.04-.263.094-.255.124-.249.171-.241.209-.985.977-.969.978-.109.1-.092.056-.109.016-.108-.016-.124-.048-.319-.17-.325-.163-.327-.155-.309-.186L21,78.422l-.668-.5-.644-.52-.62-.552-.606-.574-.574-.59-.55-.62-.542-.644-.349-.435-.319-.45-.309-.458-.287-.473-.257-.49-.239-.512L15,71.484l-.016-.109.024-.092.054-.1.078-.094.977-.97.963-.969.247-.279.179-.281.14-.287.086-.295.022-.287-.022-.3-.086-.287-.14-.287-.179-.287-.255-.279-.76-.775-.775-.761-.791-.8-.8-.791-.263-.233-.271-.179-.287-.116-.287-.086-.295-.022-.287.022-.287.086-.287.124-.271.179-.263.225-.66.66-.652.652-.66.652-.247.263-.209.273-.179.287-.14.311-.108.325-.078.343-.04.355-.022.574.03.576.07.574.117.558.147.552.17.55.186.536.35.861.379.839.411.821.442.8.474.785.511.76.536.753.558.722.582.722.666.769.707.745.712.706.745.683.768.652.783.612L18.31,83l.839.56.861.526.883.5.907.466.574.265.582.247.59.233.6.193.6.163.62.14.62.094.636.062.389.008.373-.016.363-.062.35-.078.341-.124.317-.155.319-.2.295-.233.287-.295.427-.458.449-.45.458-.442.449-.442.217-.255.179-.255.132-.257.086-.265.046-.271v-.271l-.046-.263Z" transform="translate(921.04 9734.59)" fill="#2b528e" fillRule="evenodd" />
                                        <path id="Path_3950" data-name="Path 3950" d="M17.535,66.49l.471.167.463.2.449.23.425.252.417.292.393.333.379.347.371.411.339.435.295.45.262.49.239.49.178.514.155.53.116.544,2.031-.347-.132-.631-.17-.609-.208-.6-.241-.584-.285-.553-.323-.538-.357-.522-.393-.49-.433-.474-.433-.411-.447-.379-.479-.355-.495-.316-.511-.285-.533-.252-.549-.214-.547-.174L17.4,64.3l-.579-.111-.285,2.085.493.087Z" transform="translate(925.954 9735.572)" fill="#2b528e" fillRule="evenodd" />
                                        <path id="Path_3951" data-name="Path 3951" d="M29.682,72.973l-.2-.783-.228-.777-.268-.75-.306-.736-.339-.728-.385-.7-.409-.687-.449-.663-.488-.637-.511-.615-.552-.59-.606-.6-.637-.558-.652-.509-.685-.476-.7-.436-.725-.4-.747-.363-.756-.308-.787-.266-.794-.227-.81-.186-.826-.146-.284,2.127.779.144.756.17.747.227.725.274.715.308.693.355.669.389.653.444.63.476.59.517.576.55.526.566.473.582.449.606.409.63.377.647.346.671.3.695.26.712.227.718.19.736.149.76,2.062-.365Z" transform="translate(925.953 9733.606)" fill="#2b528e" fillRule="evenodd" />
                                    </g>
                                </svg>


                            </div>
                            <div>
                                <h5 className='font-montserrat text-[15px] leading-[18px] font-bold'>Call us on :</h5>
                                <p className='font-muli text-[13px] leading-[18px] font-light'>+91 98716 88 499</p>
                            </div>
                        </div>
                        <div className='flex space-x-6 mb-[32px]'>
                            <div className='w-[26.6px] h-[26.603px]'>
                                <svg id="Group_5129" data-name="Group 5129" xmlns="http://www.w3.org/2000/svg" width="28.544" height="23.444" viewBox="0 0 28.544 23.444">
                                    <path id="Path_3953" data-name="Path 3953" d="M33.565,112.512H9.779A2.38,2.38,0,0,0,7.4,114.891V117.1l12.858,8.573a2.545,2.545,0,0,0,2.826,0L35.944,117.1v-2.207A2.38,2.38,0,0,0,33.565,112.512Z" transform="translate(-7.4 -112.512)" fill="#2b528e" />
                                    <path id="Path_3954" data-name="Path 3954" d="M21.672,125.352a4.23,4.23,0,0,1-2.356-.717L7.4,116.69v14.44a2.38,2.38,0,0,0,2.379,2.379H33.565a2.379,2.379,0,0,0,2.379-2.379V116.69l-11.916,7.945A4.23,4.23,0,0,1,21.672,125.352Z" transform="translate(-7.4 -110.065)" fill="#2b528e" />
                                </svg>



                            </div>
                            <div>
                                <h5 className='font-montserrat text-[15px] leading-[18px] font-bold'>Email us at :</h5>
                                <p className='font-muli text-[13px] leading-[18px] font-light'>galens@4basecare.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='py-8 w-full bg-primary'>
            <div className='xl:container px-4 sm:flex justify-between items-center text-white'>
                <p>© 2022 Galens. All Rights Reserved.</p>
                <p className='mt-4 sm:mt-0'>
                    <a href='https://4basecare.com/privacy-policy/' className='text-white hover:text-white/80 hover:underline'>Privacy Policy</a><span className='px-2'>|</span><a className='text-white hover:text-white/80 hover:underline' href='https://4basecare.com/terms-of-use/'>Terms and Conditions</a>

                </p>
            </div>
        </div>

    </footer>;
}
