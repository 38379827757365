import React from 'react';
import { Routes, Route } from "react-router-dom"

import Home from '../Pages/Home/Home'

const RoutesPath = () => {
    return (
        <Routes>
            <Route path="/" exact element={<Home />} />
        </Routes>
    );
}

export default RoutesPath;