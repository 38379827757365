import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react'
import {
    BookmarkAltIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorClickIcon,
    MenuIcon,
    PhoneIcon,
    PlayIcon,
    RefreshIcon,
    ShieldCheckIcon,
    SupportIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import Logo from '../../images/logo.svg'

import Scroll from 'react-scroll';
var Link = Scroll.Link;


const solutions = [
    {
        name: 'Programme Format',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: 'ProgrammeFormat',
        icon: ChartBarIcon,
        offset: -70
    },
    {
        name: 'Admission Process',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: 'AdmissionProcess',
        icon: CursorClickIcon,
        offset: -50
    },
    { name: 'Course Curriculum', description: "Your customers' data will be safe and secure.", href: 'CourseCurriculum', icon: ShieldCheckIcon, offset: -100 },
    {
        name: 'Dates to Remember',
        description: 'Build strategic funnels that will drive your customers to convert',
        href: 'DatesToRemember',
        icon: RefreshIcon,
        offset: -100
    },
]
const callsToAction = [
    { name: 'Watch Demo', href: '#', icon: PlayIcon },
    { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const Mentors = [
    {
        name: 'Our Eminent Advisors',
        description: 'Get all of your questions answered in our forums or contact support.',
        href: 'OurEminentAdvisors',
        icon: SupportIcon,
    },
    {
        name: 'Our Faculty',
        description: 'Learn how to maximize our platform to get the most out of it.',
        href: 'OurFaculty',
        icon: BookmarkAltIcon,
    },
]
const recentPosts = [
    { id: 1, name: 'Boost your conversion rate', href: '#' },
    { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
    { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = ({ openRegistrationForm }) => {

    const solutionBtnRef = useRef();
    const mentorsBtnRef = useRef();
    const resMenuRef = useRef();
    useEffect(() => {
        window.onscroll = function () {
            scrollFn()
        };


    });


    function scrollFn() {
        var navbar = document.getElementById("navbar");
        var sticky = navbar.offsetTop;
        if (window.pageYOffset >= sticky + 50 && window.screen.width >= 768) {
            navbar.classList.add("sticky")
        } else {
            navbar.classList.remove("sticky");
        }
    }
    return (
        <Popover className='relative bg-white' id="navbar">
            <div className='max-w-7xl mx-auto px-4 sm:px-6'>
                <div className='flex justify-between items-center  py-6 md:justify-start md:space-x-10'>
                    <div className='flex justify-start lg:w-0 lg:flex-1'>
                        <a href='/'>
                            <span className='sr-only'>Galens</span>
                            <img
                                className='h-12 w-auto sm:h-16'
                                src={Logo}
                                alt=''
                            />
                        </a>
                    </div>
                    <div className='-mr-2 -my-2 md:hidden flex items-center'>
                        <div className='flex flex-col items-center'>
                            <a href='//elearning.mediknit.org/galens' target='_blank' rel='noreferrer noopener'>
                                <button
                                    className='whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white bg-primary hover:bg-primary/80'>JOIN CLASS</button>
                            </a>
                            {/* <div className='text-sm'>(Opens from 5 pm on 11 April)</div> */}
                        </div>
                        <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'>
                            <span className='sr-only'>Open menu</span>
                            <MenuIcon className='h-6 w-6' aria-hidden='true' />
                        </Popover.Button>
                    </div>
                    <Popover.Group as='nav' className='hidden md:flex space-x-10 items-center'>
                        <Popover className='relative'>
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={solutionBtnRef}
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-primary'
                                        )}>
                                        <span>About The Course</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden='true'
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-200'
                                        enterFrom='opacity-0 translate-y-1'
                                        enterTo='opacity-100 translate-y-0'
                                        leave='transition ease-in duration-150'
                                        leaveFrom='opacity-100 translate-y-0'
                                        leaveTo='opacity-0 translate-y-1'>
                                        <Popover.Panel static className='absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'>
                                            <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                                <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-5'>
                                                    {solutions.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={() => solutionBtnRef.current?.click()}
                                                            spy={true} smooth={true} duration={250} offset={item.offset}
                                                            className='-m-3 p-2 flex items-start rounded-lg hover:bg-slate-300 cursor-pointer'>

                                                            <div className='ml-4'>
                                                                <p className='text-base font-medium text-gray-900'>
                                                                    {item.name}
                                                                </p>

                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>

                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <Popover className='relative'>
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        ref={mentorsBtnRef}
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-primary'
                                        )}>
                                        <span>Our Mentors</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden='true'
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-200'
                                        enterFrom='opacity-0 translate-y-1'
                                        enterTo='opacity-100 translate-y-0'
                                        leave='transition ease-in duration-150'
                                        leaveFrom='opacity-100 translate-y-0'
                                        leaveTo='opacity-0 translate-y-1'>
                                        <Popover.Panel className='absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-sm sm:px-0'>
                                            <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                                <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-5'>
                                                    {Mentors.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={() => mentorsBtnRef.current?.click()}
                                                            spy={true} smooth={true} duration={250} offset={-100}
                                                            className='-m-3 p-2 flex items-start rounded-lg hover:bg-slate-300 cursor-pointer'>

                                                            <div className='ml-4'>
                                                                <p className='text-base font-medium text-gray-900'>
                                                                    {item.name}
                                                                </p>

                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>

                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>

                        <Link
                            to={'RewardsAndRecognition'}
                            spy={true} smooth={true} duration={250} offset={-130}
                            className='text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer'>
                            Rewards
                        </Link>

                        <Link
                            to={'ContactUs'}
                            spy={true} smooth={true} duration={250}
                            className='text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer'>
                            Contact Us
                        </Link>

                        {/* <a href='#RewardsAndRecognition' className='text-base font-medium text-gray-500 hover:text-gray-900'>
                            Rewards
                        </a>
                        <a href='#ContactUs' className='text-base font-medium text-gray-500 hover:text-gray-900'>
                            Contact Us
                        </a> */}

                        <div className='flex flex-col items-center'>
                            <a href='//elearning.mediknit.org/galens' target='_blank' rel='noreferrer noopener'>
                                <button
                                    className='whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white bg-primary hover:bg-primary/80'>JOIN CLASS</button>
                            </a>
                            {/* <div>(Opens from 5 pm on 11 April)</div> */}
                        </div>

                        <a
                            href='#'
                            onClick={openRegistrationForm}
                            className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-base font-medium text-white bg-primary hover:bg-primary/80'>
                            Register Now
                        </a>


                    </Popover.Group>

                </div>
            </div>

            <Transition
                as={Fragment}
                enter='duration-200 ease-out'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='duration-100 ease-in'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Popover.Panel
                    focus
                    className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20'>
                    <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
                        <div className='pt-5 pb-6 px-5'>
                            <div className='flex items-center justify-between'>
                                <div>
                                    <img
                                        className='h-12 w-auto'
                                        src={Logo}
                                        alt='Galens Website'
                                    />
                                </div>
                                <div className='-mr-2'>
                                    <Popover.Button ref={resMenuRef} className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'>
                                        <span className='sr-only'>Close menu</span>
                                        <XIcon className='h-6 w-6' aria-hidden='true' />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className='mt-6 pb-4 border-b border-gray-50' >
                                <h5 className='mb-4 font-bold'>About The Course</h5>
                                <nav className='grid gap-y-8'>
                                    {solutions.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            onClick={() => resMenuRef.current?.click()}
                                            spy={true} smooth={true} duration={250} offset={item.offset}
                                            className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 cursor-pointer'>

                                            <span className='ml-3 text-base font-medium text-gray-900'>
                                                {item.name}
                                            </span>
                                        </Link>
                                    ))}
                                </nav>
                            </div>

                            <div className='mt-6 ' >
                                <h5 className='mb-4 font-bold'>Mentors</h5>
                                <nav className='grid gap-y-8'>
                                    {Mentors.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            onClick={() => resMenuRef.current?.click()}
                                            spy={true} smooth={true} duration={250} offset={-100}
                                            className='-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 cursor-pointer'>

                                            <span className='ml-3 text-base font-medium text-gray-900'>
                                                {item.name}
                                            </span>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className='py-6 px-5 space-y-6'>
                            <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                                {/* <a
                                    href='#'
                                    className='text-base font-medium text-gray-900 hover:text-gray-700'>
                                    About The Course
                                </a> */}

                                {/* <a
                                    href='#'
                                    className='text-base font-medium text-gray-900 hover:text-gray-700'>
                                    Docs
                                </a> */}

                                <a
                                    href='#'
                                    className='text-base font-medium text-gray-900 hover:text-gray-700'>
                                    Rewards
                                </a>
                                <a
                                    href='#'
                                    className='text-base font-medium text-gray-900 hover:text-gray-700'>
                                    Contact Us
                                </a>

                            </div>
                            <div>
                                <button
                                    type="button"
                                    onClick={openRegistrationForm}
                                    className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary/80'>
                                    Register Now
                                </button>

                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default Header;
