import React, { useEffect } from 'react';

import Header from './Header';
// import Footer from '../Footer';
// import SubPageHeader from '../Utilities/subPageHeader';

const Layout = ({ children, openRegistrationForm, subPage = true, parentClass = '', showFooter = true, subPageContent = {} }) => {
    useEffect(() => {

    }, []);

    return (
        <>
            <main className="gelens_homepage">
                <Header openRegistrationForm={openRegistrationForm} />
                {children}
            </main>
        </>
    );
};

export default Layout;
