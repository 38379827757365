import React, { Fragment, useState, } from 'react'
// import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import RecordVideos from '../../images/icons/recorded_video.svg'
import Assignments from '../../images/icons/assignments.svg'
import OnlineWorkshop from '../../images/icons/online_workshops.svg'
import TumorBoard from '../../images/icons/tumor_board.svg'
import Elearning from '../../images/icons/E_learning.svg'
import MolecularBiology from '../../images/icons/molecular_biology.svg'
import NexGen from '../../images/icons/next-gen_oncologists.svg'
import PraticalLearn from '../../images/icons/practical_learning_students.svg'
import OnlineLearning from '../../images/icons/online-learning.svg'
import SelfStudy from '../../images/icons/self-study.svg'
import ModularProgramme from '../../images/icons/modular-programme.svg'
import RegisterYourself from '../../images/icons/register_yourself.svg'
import Graduation from '../../images/icons/graduate.svg'
import Emailer from '../../images/icons/email.svg'
import Layout from '../../Components/Layout/Layout'
import HeroBg from '../../images/banner_img.png'
import EarlyBird from '../../images/icons/book.svg'
import DatesToRemember1 from '../../images/remember_1_latest.png'
import DatesToRemember2 from '../../images/remember_2_latest.png'
import ResultAnnouncement from '../../images/result_announcement.png'
import Hitesh from '../../images/thumbnails/Hitesh.png'
import IlluminaLogo from '../../images/site/illumina_logo.png'
import ThermoFisherScientific from '../../images/site/ThermoFisherScientific_logo_tag_cmyk_ez.png'
import Premos from '../../images/site/Premos.png'
import Logo from '../../images/logo.svg'
import './Home.scss'
import CourseRow from './Seactions/Course'
import TeamSlider from './Seactions/TeamSlider'
import RewardsRecognitionSlider from './Seactions/RewardsRecognitionSlider'
import FAQ from './Seactions/FAQ'
import Footer from '../../Components/Layout/Footer'
import "video-react/dist/video-react.css"
import { Dialog, Transition } from '@headlessui/react'
import Overlays from './Seactions/Overlays'
import Faculty from './Seactions/Faculty'
import Scroll from 'react-scroll'
import axios from 'axios'
import { Formik, Field, Form } from 'formik'
import StateCity from '../../library/state-city.json'
import Swal from 'sweetalert2'
import validator from 'validator'
import qs from 'qs'
var Link = Scroll.Link



const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})



const AboutCourse = [
    {
        text: (
            <p className='text-[20px] leading-[30.8px]'>
                E-learning course for <span className='font-semibold'>final year</span> students & practicing Doctors in <span className='font-semibold'>Medical/Surgical Oncology</span> and <span className='font-semibold'>Onco Pathology</span>
            </p>
        ),
        img: Elearning,
    },
    {
        text: (
            <p className='text-[20px] leading-[30.8px]'>
                E-learning based course for <span className='font-semibold'>final year</span> students of
                <span className='font-semibold'> Medical/Surgical Oncology</span> and{' '}
                <span className='font-semibold'>Onco Pathology</span>
            </p>
        ),
        img: MolecularBiology,
    },
    {
        text: (
            <p className='text-[20px] leading-[30.8px]'>
                Enable next-gen oncologists for <span className='font-semibold'>utilising genomics</span> in
                personalizing cancer treatment
            </p>
        ),
        img: NexGen,
    },
    {
        text: (
            <p className='text-[20px] leading-[30.8px]'>
                Ensures practical learning of students with{' '}
                <span className='font-semibold'>multiple case study discussions</span> and{' '}
                <span className='font-semibold'>quiz sessions</span>
            </p>
        ),
        img: PraticalLearn,
    },
];

const EMINENT_ADVISORS = [
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.AmitDutt.png',
        name: 'Dr. Amit Dutt',
        desc: 'Principal Investigator, TMC- ACTREC, Mumbai'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Anguraj.png',
        name: 'Dr. Anguraj Sadanandam',
        desc: 'Director, The Institute of Cancer Research, UK'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Aruna.png',
        name: 'Dr. Aruna Korlimarla',
        desc: 'Principal Scientist, Sri Shankara Cancer Foundation, Bangalore'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Ashok.png',
        name: 'Dr. Ashok K Vaid',
        desc: 'Chairman – Medical Oncology, Medanta, Gurgaon'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Kumar.png',
        name: 'Dr. Kumar Prabhash',
        desc: 'HOD – Medical Oncology, Tata Memorial Hospital, Mumbai'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Linu.png',
        name: 'Dr. Linu Abraham Jacob',
        desc: 'HOD - Medical Oncology, Kidwai Memorial Institute of Oncology, Bangalore'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Niti.png',
        name: 'Dr. Niti Raizada',
        desc: 'Director – Medical Oncology, Fortis Hospitals, Bangalore'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Julka.png',
        name: 'Dr. P.K. Julka',
        desc: 'Sr. Director – Medical Oncology, Max Hospital, Delhi'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Sajjan.png',
        name: 'Dr. Sajjan Rajpurohit',
        desc: 'Director – Medical Oncology, BLK-Max Hospital, Delhi'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Sewanti.png',
        name: 'Dr. Sewanti Limaye',
        desc: 'Director - Medical Oncology, Sir H.N. Reliance Foundation Hospital, Mumbai'
    }
]

const FACULTIES = [
    {
        img: process.env.REACT_APP_URL + 'images/advisors/AnneCarrera.jpeg',
        name: 'Dr. Ana Carrera',
        desc: 'Assoc Director, Regional Segment Marketing, Illumina APJ'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.AmitDutt.png',
        name: 'Dr. Amit Dutt',
        desc: 'Principal Investigator, TMC- ACTREC, Mumbai'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Anuradha.png',
        name: 'Dr. Anuradha Chougule',
        desc: 'Prof. Medical Onco, Molecular Lab - TMH, Mumbai'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Aruna.png',
        name: 'Dr. Aruna Korlimarla',
        desc: 'Principal Scientist, Sri Shankara Cancer Foundation, Bangalore'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Anguraj.png',
        name: 'Dr. Anguraj Sadanandam',
        desc: 'Director, The Institute of Cancer Research, UK'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Prof.George.png',
        name: 'Prof. George Church',
        desc: 'Harvard-MIT Health Sciences and Technology, Cambridge'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/luca.jpeg',
        name: 'Dr. Luca Quagliata',
        desc: 'Vice Present, Medical Affairs - Thermo Fisher Scientific'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Prathik.png',
        name: 'Dr. Pratik Chandrani',
        desc: 'Scientist D, TMC,',
        extra: 'Mumbai'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Prasenjit.png',
        name: 'Dr. Prasenjit Das',
        desc: 'Additional Professor - Pathology, AIIMS, New Delhi'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Richa.png',
        name: 'Richa Malhotra',
        desc: 'Head of Clinical Informatics & Reporting, 4baseCare'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Sajjan.png',
        name: 'Dr. Sajjan Rajpurohits',
        desc: 'Director – Medical Oncology, BLK-Max Hospital, Delhi'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Sewanti.png',
        name: 'Dr. Sewanti Limaye',
        desc: 'Director - Medical Oncology, Sir H.N. Reliance Foundation Hospital, Mumbai'
    },
    // {
    //     img: process.env.REACT_APP_URL + 'images/advisors/Dr.Sudharshan.png',
    //     name: 'Sudharshan Elangovan',
    //     desc: 'Functional Head - Genomics, Wipro Life Sciences'
    // },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Vidya.png',
        name: 'Dr. Vidya Veldore',
        desc: 'Clinical Director,',
        extra: '4baseCare'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/Dr.Vineet.png',
        name: 'Dr. Vineet Govinda Gupta',
        desc: 'Sr. Consultant - Medical Oncology, Artemis Hospitals, Gurgaon'
    },
    {
        img: process.env.REACT_APP_URL + 'images/advisors/YYTan.jpg',
        name: 'Yue-Ying Tan',
        desc: 'MSc, Manager, Bioinformatics Sales Specialists, Illumina APJ'
    }
]

export default function Home() {
    let [isOpen, setIsOpen] = useState(false)
    const [isDownloadProspectusOpen, setIsDownloadProspectusOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    let [youtubeLink, setYoutubeLink] = useState('')
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [prospectusEmailError, setProspectusEmailError] = useState('')
    const [registrationEmailError, setRegistrationEmailError] = useState('')
    const [showSuccessProspectus, setShowSuccessProspectus] = useState(false)
    const [showSuccessRegistration, setShowSuccessRegistration] = useState(false)
    const [file, setFile] = React.useState([])
    const [fileError, setFileError] = React.useState('')
    const [backdrop, setBackdrop] = React.useState(false)

    React.useEffect(() => {
        let stateList = Object.keys(StateCity)
        setStateList(stateList)
    }, [])

    const loadCity = (state) => {
        setCityList([])

        if (state !== 'Other') {
            let cityList = StateCity[state]
            setCityList(cityList)
        }
    }

    const showToaster = (message, type) => {
        Toast.fire({
            icon: type,
            title: message
        });
    }

    function isModalOpenFn() {
        setModalOpen(true)
    }

    function isModalCloseFn() {
        setModalOpen(false)
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const onFileChange = event => {
        if (event.target.files[0] !== undefined && event.target.files[0] !== null) {

            let uploadedFile = event.target.files[0]
            let uploadedFileName = uploadedFile.name
            let uploadedFileExtension = uploadedFileName.split('.').pop()

            const fileSize = event.target.files[0].size / 1024 / 1024

            if (uploadedFileExtension.toLowerCase() === 'pdf' || uploadedFileExtension.toLowerCase() === 'jpg' || uploadedFileExtension.toLowerCase() === 'png' || uploadedFileExtension.toLowerCase() === 'jpeg') {
                if (fileSize > 5) {
                    setFileError('File size exceed the limit')
                    setFile([])
                } else {
                    setFile(event.target.files[0])
                    setFileError('')
                }
            } else {
                setFileError('Please upload recommended file formats')
                setFile([])
            }
        } else {
            setFileError('')
            setFile([])
        }

    }

    const uploadFile = (id) => {

        setBackdrop(true)

        if(file.name !== undefined && file.name !== null && file.name !== '') {
            let formData = new FormData()

            formData.append('file', file)
            formData.append('user_id', id)

            axios({
                url: process.env.REACT_APP_API_URL + 'Users/upload',
                method: "POST",
                data: formData,
            }).then((data) => {

                setBackdrop(false)

                try {
                    if (data !== undefined && data !== null) {
                        if (data.status !== undefined && data.status !== null && data.status === 200) {
                            if (data.data !== undefined && data.data !== null) {
                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'success') {
                                    showToaster('Registered Successfully', 'success')
                                    setShowSuccessRegistration(true)
                                    setFile([])
                                    setFileError('')
                                } else if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'error') {
                                    showToaster('Error in file uploading. Please try again later', 'warning')
                                } else {
                                    showToaster('Error in file uploading. Please try again later', 'warning')
                                }
                            } else {
                                showToaster('Error in file uploading. Please try again later', 'warning')
                            }
                        } else {
                            showToaster('Error in file uploading. Please try again later', 'warning')
                        }
                    } else {
                        showToaster('Error in file uploading. Please try again later', 'warning')
                    }
                } catch (e) {
                    showToaster('Error in file uploading. Please try again later', 'warning')
                }
            }).catch((err) => {
                showToaster('Error in file uploading. Please try again later', 'warning')
            });
        } else {

        }
    }

    const openRegistrationForm = () => {
        setFile([])
        setFileError('')
        setShowSuccessRegistration(false)
        setIsOpen(true)
    }
    return (
        <>

            {backdrop && (
                <div className="fixed inset-0 top-0 left-0 right-0 bottom-0 w-full h-screen z-9999 overflow-hidden bg-gray-700 opacity-90 flex flex-col items-center justify-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
                    <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
                </div>
            )}

            <Layout openRegistrationForm={openRegistrationForm}>
                <div className='bg-gray-50 content'>
                    <div className='relative overflow-hidden'>
                        <div className='absolute inset-0'>
                            <img
                                className='h-full w-full object-cover'
                                src={HeroBg}
                                alt='People working on laptops'
                            />
                            <div className='absolute inset-0 bg-site-overlay'></div>
                        </div>

                        <div className='relative pt-6 pb-16 sm:pb-20'>
                            <div className='mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6'>
                                <div className='text-center'>
                                    <h1 className='text-center text-4xl font-normal tracking-tight sm:text-5xl lg:text-[4rem]'>
                                        <span className='text-white'>Precision Oncology</span>
                                        <span className='text-secondary'> Galens</span>
                                    </h1>
                                    <p className='mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl'>
                                        Giving an edge to <span className='text-secondary'>Next-Gen</span>{' '}
                                        Oncologists
                                    </p>
                                    <div className='mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center'>
                                        <div className='space-y-4 sm:space-y-0 sm:mx-auto flex flex-col items-center lg:flex-row'>
                                            <Link
                                                to={'AboutCourse'}
                                                spy={true} smooth={true} duration={250} offset={-180}
                                                className='flex items-center justify-center px-4 py-0 border border-white text-base font-medium rounded-md shadow-sm text-white hover:text-primary bg-transparent hover:bg-white sm:px-8 text-[18px] h-[54px] w-[220px] cursor-pointer'>
                                                Learn more
                                            </Link>
                                            <Link
                                                to={'advisors'}
                                                spy={true} smooth={true} duration={250} offset={-160}
                                                className='flex items-center justify-center px-4 py-0 text-white text-[20px] cursor-pointer'>
                                                <span className='w-14 h-14'><SmallPlay /></span>
                                                Hear what our Advisors have to say about Galens Program
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='relative'>
                            <div className='absolute inset-0 flex flex-col' aria-hidden='true'>
                                <div className='flex-1' />
                                <div className='flex-1 w-full bg-white' />
                            </div>
                            <div className='max-w-7xl mx-auto px-4 sm:px-6'>
                                <div className='relative bg-white main-features rounded py-[30px] px-[50px] md:px-[57px] grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-wrap justify-between gap-[48px] xl:gap-[60px]'>
                                    {/* icon Section 1 */}
                                    <div className='flex flex-col justify-center items-center space-y-4 lg:w-[240px]'>
                                        <img src={RecordVideos} alt='' className='w-[58px] h-[64px]' />
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-primary text-[45px] leading-[40px]'>22</div>
                                            <p className='text-[12px] leading-[18.4px] font-sans'>Recorded videos from<br />eminent faculty</p>
                                        </div>
                                    </div>

                                    {/* icon Section 3 */}
                                    <div className='flex flex-col justify-center items-center space-y-4 lg:w-[158px]'>
                                        <img src={Assignments} alt='' className='w-[58px] h-[64px]' />
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-primary text-[45px] leading-[40px]'>5</div>
                                            <p className='text-[12px] leading-[18.4px] font-sans'>Assignments &<br />Quizzes</p>
                                        </div>
                                    </div>

                                    {/* icon Section 3 */}
                                    <div className='flex flex-col justify-center items-center space-y-4 lg:w-[214px]'>
                                        <img src={TumorBoard} alt='' className='w-[58px] h-[64px]' />
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-primary text-[45px] leading-[40px]'>6+</div>
                                            <p className='text-[12px] leading-[18.4px] font-sans'>Tumor Board Case Study discussions</p>
                                        </div>
                                    </div>

                                    {/* icon Section 4 */}
                                    <div className='flex flex-col justify-center items-center space-y-4 lg:w-[260px]'>
                                        <img src={OnlineWorkshop} alt='' className='w-[58px] h-[64px]' />
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-primary text-[45px] leading-[40px]'>2</div>
                                            <p className='text-[12px] leading-[18.4px] font-sans'>Live workshops with a leading medical oncologist and clinical informatics</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='h-2 w-full'></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='bg-white mb-6'></div> */}
                </div>

                {/* Main Secrtion Edn */}

                {/* Feature Section */}
                <section className='text-gray-600 body-font mt-[80px]' style={{ backgroundImage: 'url("images/overlays/galens-bg.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '50% 50%' }} id='AboutCourse'>
                    <div className='container px-5 mx-auto max-w-7xl'>
                        <div className='text-center mb-16'>
                            <h1 className='ga-subheading'>About the Course</h1>
                            <p className='text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500 text-[26px]'>
                                Precision Oncology Galens (POG)
                            </p>
                        </div>
                        {/* <div className='flex items-center flex-col justify-center'>
                            <div className='flex flex-col text-center items-center'>
                                <div className='w-36 h-36 mb-5'>
                                    <img src={Elearning} className='w-[135px] h-[150px]' alt={'elearning'} />
                                </div>
                                <div className='flex-grow'>
                                    <h2 className='text-gray-900 text-[22px]'>E-learning course for <span className='font-semibold'>final year</span> students & practicing Doctors in<br /> <span className='font-semibold'>Medical/Surgical Oncology</span> and <span className='font-semibold'>Onco Pathology</span></h2>
                                </div>
                            </div>
                        </div> */}
                        <div className='grid sm:grid-cols-2 xl:gap-20 sm:-m-4 -mx-4  -mt-4  md:space-y-0 lg:space-y-0'>
                            {AboutCourse.map((course, index) => {
                                return (
                                    <div key={index} className='flex flex-col text-center items-center'>
                                        <div className='w-36 h-36 mb-5'>
                                            <img src={course.img} className='w-[135px] h-[150px]' alt={course.text} />
                                        </div>
                                        <div className='flex-grow'>
                                            <h2 className='text-gray-900'>{course.text}</h2>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <button className='mx-auto ga-btn-secondary mt-[65px] text-[22px] font-montserrat' onClick={openRegistrationForm}>REGISTER NOW</button>
                    </div>
                </section>
                {/* Feature Section End */}

                {/* CEO Section Start */}


                <section className='w-full mt-[80px]'>
                    <div className='text-center'>
                        <h1 className='ga-subheading'>Hear what our CEO has to say</h1>
                        <p className='text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500 text-[26px] mt-[17px]'>
                            - Hitesh Goswami, CEO, 4baseCare
                        </p>
                    </div>

                </section>
                <section className='mt-[65px] section-bg w-full flex justify-center bg-[#FAFAFA] relative'>
                    <Overlays />
                    <div className='relative  m-4 lg:m-0  w-[917px] flex items-center justify-center z-20'>
                        <div className='flex w-full h-full justify-center items-center'>
                            {/* <Player aspectRatio="16:9" poster={Hitesh} src={"https://youtu.be/EAqMLxm16J4"}>
                                <BigPlayButton position="center" />
                            </Player> */}
                            <img src='./assets/utubeTumb/Hitesh.png' alt='Hitesh' className='cursor-pointer' onClick={isModalOpenFn} />
                        </div>
                    </div>

                </section>
                {/* CEO Section End */}


                <section className="section-bg w-full py-[80px] mt-[80px] m-auto font-muli relative bg-[#FAFAFA]" id="ProgrammeFormat">
                    <Overlays showBtm={false} />
                    <div className='text-center relative z-20'>
                        <h1 className='ga-subheading'>Programme Format</h1>
                        <p className='leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500 text-[26px] mt-[17px]'>Precision Oncology Galens (POG)</p>
                        <a className='mx-auto ga-btn-secondary mt-[33px]' onClick={openRegistrationForm}>REGISTER HERE</a>
                    </div>

                    <div className="container px-[20px] lg:px-2 max-w-7xl mt-[72px] relative z-20">
                        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0 sm:-m-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                            <div className="flex flex-col text-center items-center lg:border-r border-[#D5D5D5] px-0 lg:pr-[58.5px]">
                                <img className='w-[135px] h-[150px] mb-[18px]' src={OnlineLearning} alt='Online Learning' />
                                <p className="text-[22px] leading-[30.8px] text-primary mb-[16px]">Online Learning</p>
                                <p className=" text-[16px] leading-[22.4px]">Interactive e-platform for participants to engage in learning. Platform predominantly used for tutorials and course content</p>
                            </div>
                            <div className="flex flex-col text-center items-center lg:border-r border-[#D5D5D5] px-0 lg:pr-[58.5px] lg:pl-[58.5px]">
                                <img className='w-[135px] h-[150px] mb-[18px]' src={SelfStudy} alt='Self Study' />
                                <p className="text-[22px] leading-[30.8px] text-primary mb-[16px]">Self Study</p>
                                <p className=" text-[16px] leading-[22.4px]">4-5 hours of self study is required to complete each module through our Learning Management System</p>
                            </div>
                            <div className="flex flex-col text-center items-center px-0 lg:pl-[58.5px]">
                                <img className='w-[135px] h-[150px] mb-[18px]' src={ModularProgramme} alt='Modular Programme' />
                                <p className="text-[22px] leading-[30.8px] text-primary mb-[16px]">Modular Programme</p>
                                <p className=" text-[16px] leading-[22.4px]">Learning format to allow busy clinicians with the flexibility of learning with minimal work disruption</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CEO Section Start */}
                <section className='w-full mt-[65px] flex flex-col items-center' id='CourseCurriculum'>
                    <div className='w-full text-center'>
                        <h1 className='ga-subheading'>Course Curriculum</h1>
                        <p className='leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500 text-[26px] mt-[17px]'>Precision Oncology Galens</p>
                        <a className='mt-[33px] mx-auto ga-btn-secondary' onClick={() => { setCityList([]); setShowSuccessProspectus(false); setIsDownloadProspectusOpen(true) }}>DOWNLOAD PROSPECTUS</a>
                    </div>
                </section>
                <section className='container px-2 max-w-7xl mt-[64px]'>
                    <CourseRow />
                </section>

                <section className="section-bg w-full py-[80px] mt-[80px] m-auto font-muli relative bg-[#FAFAFA]" id="AdmissionProcess">
                    <Overlays showBtm={false} />
                    <div className='text-center relative z-20'>
                        <h1 className='ga-subheading'>Admission Process</h1>
                        <a className='mx-auto ga-btn-secondary mt-8' onClick={openRegistrationForm}>REGISTER HERE</a>
                    </div>

                    <div className="container mx-auto mt-[72px] px-[20px] lg:px-[14px]">
                        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-[60px] md:gap-[117px] sm:-m-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                            <div className="flex flex-col text-center items-center">
                                <div className="w-auto inline-flex items-center justify-center  mb-5">
                                    <img className='w-[135px] h-[150px]' src={RegisterYourself} alt='Register yourself' />
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-[18px] lg:text-[14px] leading-[25.2px]">Register yourself and submit<br />the Registration Form</p>
                                </div>
                            </div>
                            <div className="flex flex-col text-center items-center">
                                <div className="w-auto inline-flex items-center justify-center  mb-5">
                                    <img className='w-[135px] h-[150px]' src={Graduation} alt='Register yourself' />
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-[18px] lg:text-[14px] leading-[25.2px]">Only Final year Students (DM, DNB, M. Ch students in Medical/Surgical Oncology and Onco Pathology) & practicing Oncologists (Medical/Surgical) / Pathologists are eligible to apply</p>
                                </div>
                            </div>
                            <div className="flex flex-col text-center items-center">
                                <div className="w-auto inline-flex items-center justify-center  mb-5">
                                    <img className='w-[135px] h-[150px]' src={Emailer} alt='Register yourself' />
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-[18px] lg:text-[14px] leading-[25.2px]">After submission, candidates will be<br />informed by emailers</p>
                                </div>
                            </div>
                        </div>

                    </div>


                </section>

                {/* Avail Early-Bird Section Start */}

                <section className='py-20'>
                    <div className='container  max-w-7xl' style={{ backgroundImage: 'url("images/overlays/early-bird-bg.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
                        <div className='grid lg:grid-cols-3 gap-5 lg:gap-0'>
                            <div className='lg:col-span-2'>
                                {/* <h4 className='font-verdana text-[42px] leading-[100px] text-primary'>Avail Early-Bird</h4>
                                <div className='font-bebas text-[90px] leading-[100px] md:text-[5.625rem] text-primary'>DISCOUNTS!</div> */}
                                <div className='tracking-[0.1rem] font-bebas text-[54px] md:text-[64px] leading-[100px] text-primary'>IT'S UNBELIEVABLE</div>
                                <div className='font-muli font-light text-[26px] max-w-[35rem] text-[#3E3E3E] leading-[44.8px]'>In just <span className='font-semibold'>24 hours</span> of opening the registration, all <span className='font-semibold'>50 seats</span> are filled</div>
                                <div className='mt-[30px] font-muli font-light max-w-2xl text-[26px] text-[#3E3E3E] leading-[44.8px]'>With this tremendous response and further requests, we are happy to open up <span className='font-semibold text-primary'>25 more seats</span>.</div>
                                {/* <div className='font-muli font-light text-[32px] max-w-lg text-[#3E3E3E] leading-[44.8px]'>Register before 31st March’ 22 to avail the Course for</div>
                                <div className='font-bebas text-[80px] leading-[100px] md:text-[5.625rem] text-primary'>FOR FREE!</div>
                                <div className='mt-[30px] tracking-[0.1rem] font-bebas text-[32px] leading-[50px] text-primary'>HURRY UP! ONLY 50 SEATS ARE AVAILABLE</div> */}
                                <a className='mt-[49px] font-montserrat ga-btn-secondary ml-0 text-[22px] w-[365px] h-[75px] ' onClick={openRegistrationForm}>REGISTER NOW</a>
                                <div className='mt-[111px]'>
                                    <p className='text-[16px] italic'>*Registration will close on 31st March 2022</p>
                                    <p className='text-[16px] italic'>*Applicable for Final year Students (DM, DNB, M.Ch students in Medical/Surgical</p>
                                    <p className='text-[16px] italic whitespace-normal lg:whitespace-nowrap'>Oncology and Onco Pathology) & practicing Oncologists (Medical/Surgical) / Pathologists</p>
                                </div>
                            </div>
                            <div className='flex items-center justify-center lg:items-start lg:justify-end'>
                                <img src={EarlyBird} alt='Early Bird' />
                            </div>
                        </div>
                    </div>

                </section>


                {/* Avail Early-Bird Section End */}

                {/* Dates to Remember Section Start */}

                <section className="section-bg bg-[#FAFAFA] w-full py-12 mb-[80px] m-auto font-muli relative" id="DatesToRemember">
                    <Overlays />
                    <div className='text-center mb-16 relative z-20'>
                        <h1 className='ga-subheading'>Dates to Remember</h1>

                        <a className='mx-auto ga-btn-secondary mt-8' onClick={openRegistrationForm}>REGISTER HERE</a>
                    </div>

                    <div className='container  max-w-7xl'>

                        <div className='grid md:grid-cols-2 gap-x-8 relative z-20'>
                            <img src={DatesToRemember1} alt='' className='w-full' />
                            <img src={DatesToRemember2} alt='' className='mt-4 md:mt-0 w-full' />
                        </div>
                        {/* <p className='text-[#444444] text-[12px] leading-[22.4px] font-muli'><span className='text-primary'> Note :</span> While every effort will be made not to change the dates of the course, 4baseCare reserves the right to modify the schedule in case of extraneous reasons.</p> */}

                        <div className='mt-12 flex items-center justify-center'>
                            <img src={ResultAnnouncement} alt='result' />
                        </div>
                    </div>



                </section>
                {/* Dates to Remember Section End */}

                {/* Our Eminent Advisors Section Start */}

                <section className='w-full py-10 mb-10' id='OurEminentAdvisors'>
                    <div className='text-center mb-[50px] relative z-20'>
                        <h1 className='ga-subheading'>Our Eminent Advisors</h1>
                    </div>
                    <div className='container  max-w-7xl'>
                        <TeamSlider team={EMINENT_ADVISORS} />
                    </div>
                </section>

                {/* Our Eminent Advisors Section End */}

                {/* Our  faculty Section Start */}

                <Faculty />

                {/* Our  faculty Section End */}

                {/* Video Mointor section Start */}

                <section className='w-full py-10' id='OurFaculty'>
                    <div className='text-center mb-16 relative z-20'>
                        <h1 className='ga-subheading'>Our Faculty</h1>
                    </div>
                    <div className='container  max-w-7xl'>
                        <TeamSlider team={FACULTIES} />
                    </div>
                </section>




                {/* Rewards & Recognition Section Start */}

                <RewardsRecognitionSlider />

                {/* Rewards & Recognition Section End */}

                {/* faq Section Start */}
                {/* <FAQ /> */}
                {/* faq Section End */}

                {/* Our Parters Section */}
                <section className='w-full py-10 relative'>
                    <Overlays showTop={false} />
                    <div className='container  max-w-7xl relative z-20'>
                        <div className='text-center mb-16 flex flex-col items-center space-y-3'>
                            <h1 className='ga-subheading'>Our Partners</h1>
                            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0">
                                <div className='flex justify-center items-center'>
                                    <img src={ThermoFisherScientific} alt='ThermoFisherScientific Logo' />
                                </div>
                                <div className='flex justify-center items-center'>
                                    <img src={IlluminaLogo} alt='Illumina Logo' />
                                </div>
                                <div className='flex justify-center items-center'>
                                    <img src={Premos} alt='Premos Logo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Our Parters Section */}

                {/* Footer Start */}
                <Footer />
                {/* Footer End */}



            </Layout>


            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-999 overflow-y-auto"
                    onClose={setModalOpen}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >

                            <Dialog.Overlay className="fixed inset-0 bg-slate-700 opacity-70" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-5xl p-6 my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="div"
                                    className="text-lg font-medium leading-6 pb-4 text-primary flex flex-col-reverse md:flex-row md:justify-between items-center border-b-2 border-gray-100"
                                >
                                    <h6 className="text-[26px] leading-[25.2px] font-verdana">Registration Form</h6>
                                    <img src={Logo} alt='' className="w-32" />
                                    <div className='h-10 w-10 rounded-full bg-white absolute right-[0] xl:right-[50%] xl:-right-12 -top-0  sm:-top-0 flex justify-center items-center cursor-pointer' onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    </div>
                                </Dialog.Title>
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        mobile_number: '',
                                        college_institute_hospital_name: '',
                                        country: '',
                                        state: '',
                                        city: '',
                                        current_education: '',
                                        current_occupation: '',
                                        speciality: ''
                                    }}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        if (!validator.isEmail(values.email)) {
                                            setRegistrationEmailError('Please enter valid email')
                                            document.getElementById('register-email').focus()
                                        } else if(file.name === undefined || file.name === null || file.name === '') {
                                            setFileError('Please upload file')
                                        } else {

                                            setBackdrop(true)
                                            setFileError('')
                                            setRegistrationEmailError('')

                                            axios.post(process.env.REACT_APP_API_URL + 'Users/register', qs.stringify(values)).then((data) => {
                                                setBackdrop(false)
                                                try {
                                                    if (data !== undefined && data !== null) {
                                                        if (data.status !== undefined && data.status !== null && data.status === 200) {
                                                            if (data.data !== undefined && data.data !== null) {
                                                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'success') {
                                                                    uploadFile(data.data.user_id)
                                                                } else if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'error') {
                                                                    showToaster('Please try again later', 'warning')
                                                                } else if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'already') {
                                                                    setRegistrationEmailError((data.data.message !== undefined && data.data.message !== null) ? data.data.message : 'Email already exists')

                                                                    document.getElementById('register-email').focus()
                                                                } else {
                                                                    showToaster('Please try again later', 'warning')
                                                                }
                                                            } else {
                                                                showToaster('Please try again later', 'warning')
                                                            }
                                                        } else {
                                                            showToaster('Please try again later', 'warning')
                                                        }
                                                    } else {
                                                        showToaster('Please try again later', 'warning')
                                                    }
                                                } catch (e) {
                                                    showToaster('Please try again later', 'warning')
                                                }
                                            }).catch(err => {
                                                setBackdrop(false)
                                                showToaster('Please try again later', 'warning')
                                            })
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        handleReset,
                                        setFieldValue
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            {!showSuccessRegistration && (
                                                <React.Fragment>
                                                    <div className="mt-6">
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Name*</span>
                                                                <input
                                                                    name="name"
                                                                    value={values.name}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Email ID*</span>
                                                                <input
                                                                    name="email"
                                                                    id="register-email"
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                                {registrationEmailError !== '' && (
                                                                    <span className='text-red-400'>{registrationEmailError}</span>
                                                                )}
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Mobile Number*</span>
                                                                <input
                                                                    name="mobile_number"
                                                                    value={values.mobile_number}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">College/Institute/Hospital Name*</span>
                                                                <input
                                                                    name="college_institute_hospital_name"
                                                                    value={values.college_institute_hospital_name}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Country*</span>
                                                                <input
                                                                    name="country"
                                                                    value={values.country}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">State*</span>
                                                                {/* <select
                                                                    name='state'
                                                                    value={values.state}
                                                                    onChange={(e) => {
                                                                        loadCity(e.target.value)
                                                                        setFieldValue("state", e.target.value)
                                                                        setFieldValue("city", '')
                                                                    }}
                                                                    required
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Other'>Other</option>
                                                                    {stateList.length > 0 && stateList.map((state, index) => {
                                                                        return (
                                                                            <option value={state} key={'state-' + index}>{state}</option>
                                                                        )
                                                                    })}
                                                                </select> */}
                                                                <input
                                                                    name="state"
                                                                    value={values.state}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">City*</span>
                                                                {/* <select
                                                                    name='city'
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    required
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Other'>Other</option>
                                                                    {cityList.length > 0 && cityList.map((city, index) => {
                                                                        return (
                                                                            <option value={city} key={'city-' + index}>{city}</option>
                                                                        )
                                                                    })}
                                                                </select> */}
                                                                <input
                                                                    name="city"
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    required
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Current Occupation*</span>
                                                                <select
                                                                    name='current_occupation'
                                                                    value={values.current_occupation}
                                                                    onChange={(e) => {
                                                                        setFieldValue("current_occupation", e.target.value)
                                                                        setFieldValue("speciality", '')
                                                                        setFieldValue("current_education", '')
                                                                    }}
                                                                    required
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Student'>Student</option>
                                                                    <option value='Practicing Oncologist'>Practicing Oncologist</option>
                                                                </select>
                                                            </label>
                                                            
                                                            <label className="block">
                                                                {values.current_occupation === 'Practicing Oncologist' && (
                                                                    <>
                                                                        <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Speciality*</span>
                                                                        <select
                                                                            name='speciality'
                                                                            value={values.speciality}
                                                                            onChange={handleChange}
                                                                            required
                                                                            className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            <option value='Medical oncology'>Medical oncology</option>
                                                                            <option value='Surgical oncology'>Surgical oncology</option>
                                                                            <option value='Onco pathology'>Onco pathology</option>
                                                                        </select>
                                                                    </>
                                                                )}

                                                                {values.current_occupation === 'Student' && (
                                                                    <>
                                                                        <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Current Education*</span>
                                                                        <select
                                                                            name='current_education'
                                                                            value={values.current_education}
                                                                            onChange={handleChange}
                                                                            required
                                                                            className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            <option value='DM Medical Oncology Final Year'>DM Medical Oncology Final Year</option>
                                                                            <option value='DM Onco Pathology Final Year'>DM Onco Pathology Final Year</option>
                                                                            <option value='DM Medical Genetics Final Year'>DM Medical Genetics Final Year</option>
                                                                            <option value='DM Paediatric Oncology Final Year'>DM Paediatric Oncology Final Year</option>
                                                                            <option value='DNB Gynaecological Oncology'>DNB Gynaecological Oncology</option>
                                                                            <option value='DNB Medical Genetics Final Year'>DNB Medical Genetics Final Year</option>
                                                                            <option value='DNB Medical Oncology Final Year'>DNB Medical Oncology Final Year</option>
                                                                            <option value='DNB Surgical Oncology Final Year'>DNB Surgical Oncology Final Year</option>
                                                                            <option value='MCh Surgical Oncology Final Year'>MCh Surgical Oncology Final Year</option>
                                                                            <option value='MCh Gynaecological Oncology Final Year'>MCh Gynaecological Oncology Final Year</option>
                                                                        </select>
                                                                    </>
                                                                )}
                                                            </label>
                                                            
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Upload your latest education credentials*</span>
                                                                <div className="form-input mt-1 block w-full border-slate-300 h-16 rounded-md hover:ring-1 hover:ring-primary/90">
                                                                    <div className='flex w-full h-full justify-between p-2 items-center relative  cursor-pointer text-primary'>
                                                                        <span className='font-verdana text-[22px]'>Upload</span>
                                                                        <span className='font-verdana font-bold text-3xl'>+</span>
                                                                        <input type='file' className='hidden absolute inset-0 z-20 cursor-pointer' onChange={onFileChange} />
                                                                    </div>
                                                                </div>
                                                                <p className='text-red-900 text-[12px] mt-2 antialiased'>* Supported Formats: jpg, png, pdf</p>
                                                                <p className='text-red-900 text-[12px]'>* Max File Size: 5MB</p>
                                                                {file.name !== undefined && file.name !== null && file.name !== '' && (
                                                                    <span className='text-primary'>{file.name}</span>
                                                                )}
                                                                {fileError !== '' && (
                                                                    <span className='text-red-400'>{fileError}</span>
                                                                )}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="mt-16 flex justify-center">
                                                        <button
                                                            type="submit"
                                                            className="inline-flex justify-center items-center px-4 py-2 font-montserrat font-bold uppercase text-[17px] md:text-[20px] leading-[34px] w-full h-12 sm:w-[365px] sm:h-[75px] text-white bg-primary border border-transparent rounded-[3px] hover:bg-primary/80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                        >
                                                            PROCEED FOR REGISTRATION
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {showSuccessRegistration && (
                                                <div className="mt-16 mb-16 flex justify-center">
                                                    <h3 className='text-2xl'>Thank You for registering, the current batch is full. We will keep you updated on the next batch.</h3>
                                                </div>
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={isDownloadProspectusOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-999 overflow-y-auto"
                    onClose={setModalOpen}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >

                            <Dialog.Overlay className="fixed inset-0 bg-slate-700 opacity-70" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-5xl p-6 my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="div"
                                    className="text-lg font-medium leading-6 pb-4 text-primary flex flex-col-reverse md:flex-row md:justify-between items-center border-b-2 border-gray-100"
                                >
                                    <h6 className="text-[26px] leading-[25.2px] font-verdana">Download Prospectus</h6>
                                    <img src={Logo} alt='' className="w-32" />
                                    <div className='h-10 w-10 rounded-full bg-white absolute right-[0] xl:right-[50%] xl:-right-12 -top-0  sm:-top-0 flex justify-center items-center cursor-pointer' onClick={() => setIsDownloadProspectusOpen(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    </div>
                                </Dialog.Title>
                                {/* <div className="mt-6">
                                    <div className="grid">
                                        <iframe height="649" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" scrolling="no" style={{ width: '100%', border: 'none' }} src="https://mediknit.wufoo.com/embed/z4vem2v11vh3ow/"></iframe>
                                    </div>
                                </div> */}

                                {/* <div className="mt-16 flex justify-center">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center px-4 py-2 font-montserrat font-bold uppercase text-[22px] leading-[34px] w-full h-12 sm:w-[365px] sm:h-[75px] text-white bg-primary border border-transparent rounded-[3px] hover:bg-primary/80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={closeModal}
                                    >
                                        PROCEED FOR PAYMENT
                                    </button>
                                </div> */}

                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        mobile_number: '',
                                        college_institute_hospital_name: '',
                                        state: '',
                                        city: '',
                                        current_occupation: '',
                                        current_education: '',
                                        speciality: ''
                                    }}
                                    onSubmit={async (values, { setSubmitting }) => {

                                        if (!validator.isEmail(values.email)) {
                                            setProspectusEmailError('Please enter valid email')
                                            document.getElementById('email').focus()
                                        } else {

                                            setBackdrop(true)
                                            setProspectusEmailError('')

                                            axios.post(process.env.REACT_APP_API_URL + 'Prospectus/save', qs.stringify(values)).then((data) => {
                                                setBackdrop(false)
                                                try {
                                                    if (data !== undefined && data !== null) {
                                                        if (data.status !== undefined && data.status !== null && data.status === 200) {
                                                            if (data.data !== undefined && data.data !== null) {
                                                                if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'success') {
                                                                    showToaster('Saved Successfully', 'success')
                                                                    setShowSuccessProspectus(true)
                                                                } else if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'error') {
                                                                    showToaster('Please try again later', 'warning')
                                                                } else if (data.data.status !== undefined && data.data.status !== null && data.data.status === 'already') {
                                                                    setProspectusEmailError((data.data.message !== undefined && data.data.message !== null) ? data.data.message : 'Email already exists')

                                                                    document.getElementById('email').focus()
                                                                } else {
                                                                    showToaster('Please try again later', 'warning')
                                                                }
                                                            } else {
                                                                showToaster('Please try again later', 'warning')
                                                            }
                                                        } else {
                                                            showToaster('Please try again later', 'warning')
                                                        }
                                                    } else {
                                                        showToaster('Please try again later', 'warning')
                                                    }
                                                } catch(e) {
                                                    showToaster('Please try again later', 'warning')
                                                }
                                            }).catch(err => {
                                                setBackdrop(false)
                                                showToaster('Please try again later', 'warning')
                                            })
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        handleReset,
                                        setFieldValue
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="mt-6">
                                                {!showSuccessProspectus && (
                                                    <React.Fragment>
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Name*</span>
                                                                <input
                                                                    name='name'
                                                                    value={values.name}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Email ID*</span>
                                                                <input
                                                                    name='email'
                                                                    id='email'
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                                {prospectusEmailError !== '' && (
                                                                    <span className='text-red-400'>{prospectusEmailError}</span>
                                                                )}
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Mobile Number*</span>
                                                                <input
                                                                    name='mobile_number'
                                                                    value={values.mobile_number}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">College/Institute/Hospital Name*</span>
                                                                <input
                                                                    name='college_institute_hospital_name'
                                                                    value={values.college_institute_hospital_name}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Country*</span>
                                                                <input
                                                                    name='country'
                                                                    value={values.country}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">State*</span>
                                                                {/* <select
                                                                    name='state'
                                                                    value={values.state}
                                                                    required
                                                                    onChange={(e) => {
                                                                        loadCity(e.target.value)
                                                                        setFieldValue("state", e.target.value)
                                                                        setFieldValue("city", '')
                                                                    }}
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Other'>Other</option>
                                                                    {stateList.length > 0 && stateList.map((state, index) => {
                                                                        return (
                                                                            <option value={state} key={'state-' + index}>{state}</option>
                                                                        )
                                                                    })}
                                                                </select> */}
                                                                <input
                                                                    name='state'
                                                                    value={values.state}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">City*</span>
                                                                {/* <select
                                                                    name='city'
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    required
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Other'>Other</option>
                                                                    {cityList.length > 0 && cityList.map((city, index) => {
                                                                        return (
                                                                            <option value={city} key={'city-' + index}>{city}</option>
                                                                        )
                                                                    })}
                                                                </select> */}
                                                                <input
                                                                    name='city'
                                                                    value={values.city}
                                                                    onChange={handleChange}
                                                                    required
                                                                    type="text"
                                                                    className="form-input text-xl mt-1 block w-full border-slate-300 h-16  rounded-md focus:ring-1 focus:ring-primary/60"
                                                                />
                                                            </label>
                                                            <label className="block">
                                                                <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Current Occupation*</span>
                                                                <select
                                                                    name='current_occupation'
                                                                    value={values.current_occupation}
                                                                    onChange={(e) => {
                                                                        setFieldValue("current_occupation", e.target.value)
                                                                        setFieldValue("speciality", '')
                                                                        setFieldValue("current_education", '')
                                                                    }}
                                                                    required
                                                                    className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                >
                                                                    <option value=''>Select</option>
                                                                    <option value='Student'>Student</option>
                                                                    <option value='Practicing Oncologist'>Practicing Oncologist</option>
                                                                </select>
                                                            </label>

                                                            <label className="block">
                                                                {values.current_occupation === 'Practicing Oncologist' && (
                                                                    <>
                                                                        <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Speciality*</span>
                                                                        <select
                                                                            name='speciality'
                                                                            value={values.speciality}
                                                                            onChange={handleChange}
                                                                            required
                                                                            className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            <option value='Medical oncology'>Medical oncology</option>
                                                                            <option value='Surgical oncology'>Surgical oncology</option>
                                                                            <option value='Onco pathology'>Onco pathology</option>
                                                                        </select>
                                                                    </>
                                                                )}

                                                                {values.current_occupation === 'Student' && (
                                                                    <>
                                                                        <span className="font-verdana text-base leading-[25.2px] text-[#373F41]">Current Education*</span>
                                                                        <select
                                                                            name='current_education'
                                                                            value={values.current_education}
                                                                            onChange={handleChange}
                                                                            required
                                                                            className='form-select mt-1 block w-full border-slate-300 h-16 rounded-md focus:ring-1 focus:ring-primary/60'
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            <option value='DM Medical Oncology Final Year'>DM Medical Oncology Final Year</option>
                                                                            <option value='DM Onco Pathology Final Year'>DM Onco Pathology Final Year</option>
                                                                            <option value='DM Medical Genetics Final Year'>DM Medical Genetics Final Year</option>
                                                                            <option value='DM Paediatric Oncology Final Year'>DM Paediatric Oncology Final Year</option>
                                                                            <option value='DNB Gynaecological Oncology'>DNB Gynaecological Oncology</option>
                                                                            <option value='DNB Medical Genetics Final Year'>DNB Medical Genetics Final Year</option>
                                                                            <option value='DNB Medical Oncology Final Year'>DNB Medical Oncology Final Year</option>
                                                                            <option value='DNB Surgical Oncology Final Year'>DNB Surgical Oncology Final Year</option>
                                                                            <option value='MCh Surgical Oncology Final Year'>MCh Surgical Oncology Final Year</option>
                                                                            <option value='MCh Gynaecological Oncology Final Year'>MCh Gynaecological Oncology Final Year</option>
                                                                        </select>
                                                                    </>
                                                                )}
                                                            </label>
                                                        </div>
                                                        <div className="mt-16 flex justify-center">
                                                            <button
                                                                type="submit"
                                                                className="inline-flex justify-center items-center px-4 py-2 font-montserrat font-bold uppercase text-[22px] leading-[34px] w-full h-12 sm:w-[365px] sm:h-[75px] text-white bg-primary border border-transparent rounded-[3px] hover:bg-primary/80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                            >SUBMIT</button>
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                                {showSuccessProspectus && (
                                                    <div className="mt-16 mb-16 flex justify-center">
                                                        <h3 className='text-2xl'>Thank you for sharing your details. Our team will get in touch with you shortly.</h3>
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={isModalCloseFn}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-5xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl">
                                <iframe className='w-full h-[300px] md:h-[500px]' src="https://www.youtube.com/embed/EAqMLxm16J4?autoplay=1&mute=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

        </>
    );
}


function SmallPlay() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76">
            <defs>
                <filter id="a" width={76} height={76} x={0} y={0} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur result="blur" stdDeviation={3} />
                    <feFlood floodOpacity=".161" />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 3348">
                <g filter="url(#a)" transform="translate(-.005 -.005)">
                    <circle cx={29} cy={29} r={29} fill="#fff" data-name="Ellipse 7" opacity=".22" transform="translate(9 6)" />
                </g>
                <path fill="#fff" d="M38.425 11.625a23.8 23.8 0 1 1-23.8 23.8 23.8 23.8 0 0 1 23.8-23.8Z" data-name="Path 53" />
                <path d="M45.689 34.986 34.26 41.517V28.455Z" data-name="Path 3359" />
            </g>
        </svg>

    )
}




