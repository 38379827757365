export const ChevronDown = ({ size = 24, color = '#000000', parentClass = '' }) => (
    <svg
        className={parentClass}
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path d='M6 9l6 6 6-6' />
    </svg>
);

export const ClockIcon = ({ size = 24, color = '#000000', parentClass = '' }) => (
    <svg data-name="Group 5263" className={parentClass} xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 1210" fill="#2b528e" d="M0 0h19v21.499H0z" />
            </clipPath>
        </defs>
        <path data-name="Path 4213" d="M13.398 13.553H8.224V7.041h1.826v4.686h3.347Z" fill="#2b528e" />
        <path data-name="Rectangle 1208" fill="#2b528e" d="M7.637 0h3.195v1.826H7.637z" />
        <path data-name="Rectangle 1209" fill="#2b528e" d="m15.622 4.268 1.291-1.291 2.088 2.087-1.292 1.291z" />
        <g data-name="Group 5262">
            <g data-name="Group 5261" clipPath="url(#a)">
                <path data-name="Path 4214" d="M9.247 21.498a9.247 9.247 0 1 1 9.247-9.247 9.274 9.274 0 0 1-9.247 9.247Zm0-16.76a7.513 7.513 0 1 0 7.513 7.513 7.535 7.535 0 0 0-7.513-7.513" fill="#2b528e" />
            </g>
        </g>
    </svg>

);

// export const MenuIcon = ({ size = 24, color = '#000000', parentClass = '' }) => (
//     <svg
//         className='stroke-black stroke-1'
//         xmlns='http://www.w3.org/2000/svg'
//         width={size}
//         height={size}
//         viewBox='0 0 24 24'
//         fill='none'
//         //stroke='{color}'
//         strokewidth={2}
//         strokelinecap='round'
//         strokelinejoin='round'>
//         <line x1={3} y1={12} x2={21} y2={12} />
//         <line x1={3} y1={6} x2={21} y2={6} />
//         <line x1={3} y1={18} x2={21} y2={18} />
//     </svg>
// );

export const MenuIcon = ({ size = 24, color = '#2B528E', parentClass = '' }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        //className='stroke-primary stroke-1'
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <line x1='3' y1='12' x2='21' y2='12'></line>
        <line x1='3' y1='6' x2='21' y2='6'></line>
        <line x1='3' y1='18' x2='21' y2='18'></line>
    </svg>
);
