import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import Overlays from './Overlays'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { Navigation, FreeMode } from "swiper"

const Videos = [
    {
        img: './assets/utubeTumb/Dr.Julka.png',
        name: '- Dr. Pramod Kumar Julka, Senior Director, Max Institute Of Cancer Care',
        video_url: 'gxqGZkOolCc'
    },
    {
        img: './assets/utubeTumb/Dr.Kumar.png',
        name: '- Dr. Kumar Prabhash, HOD - Medical Oncology, Tata Memorial Hospital, Mumbai',
        video_url: 'j_7JcRAhO-s'
    },
    {
        img: './assets/utubeTumb/Dr.Niti.png',
        name: '- Dr. Niti Raizada, Director - Medical & Hemato Oncology, Fortis Group Of Hospitals, Bangalore',
        video_url: 'LhhLXwLTcfQ'
    },
    {
        img: './assets/utubeTumb/Dr.Linu.png',
        name: '- . Linu Abraham Jacob, HOD - Medical Oncology, Kidwai Memorial Institute Of Oncology, Bangalore',
        video_url: 'eyaB26PdX1w'
    },
]

export default function Faculty() {
    let [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [poster, setPoster] = useState('')
    function closeModal() {
        setIsOpen(false)
    }
    function openModal(video) {
        setUrl(video.video_url)
        setPoster(video.img)
        setIsOpen(true)
    }
    return (
        <>
            <section className='w-full py-10 overflow-hidden relative bg-[#FAFAFA]' id='advisors'>
                <Overlays />
                <div className='container'>
                    <div className='text-center relative z-20'>
                        <h1 className='ga-subheading'>Hear what our Advisors have to say about POG</h1>
                    </div>
                    <Swiper navigation={true} slidesPerView={1}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                            },
                        }}
                        loop={true}
                        loopFillGroupWithBlank={true} modules={[Navigation]} className="teams-slider">
                        {
                            Videos.map((video, index) => {

                                return (
                                    <SwiperSlide key={index}>
                                        <div className='container flex flex-col items-center justify-center w-full relative z-20 mt-[15px]'>
                                            <p className='leading-relaxed mx-auto text-center text-gray-500 text-[26px] mb-[33px]'>{video.name}</p>
                                            <img src={video.img} alt={video.name} className="w-full lg:w-[900px] cursor-pointer" onClick={() => openModal(video)} />
                                        </div>
                                    </SwiperSlide>
                                )

                            })
                        }
                    </Swiper>
                </div>
            </section>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 z  bg-black opacity-30" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-5xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl">

                                <iframe className='w-full h-[300px] md:h-[500px]' src={`https://www.youtube.com/embed/${url}?autoplay=1`} title="YouTube video  " frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                {/* <Player
                                    playsInline
                                    autoPlay={true}
                                    aspectRatio="16:9"
                                    poster={poster}
                                    src={url}
                                >
                                    <BigPlayButton position="center" />
                                </Player> */}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

