import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDown, ClockIcon, MenuIcon } from '../../../Components/Utilities/Icons'



export default function CourseModules() {
    return (
        <>
            <div className="mt-[64px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Module 1</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>4 Hours</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>1 Week</span></div>
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-6 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-4'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>Introduction to Precision Medicine and its application in Cancer Care</li>
                                                        <li>Fundamentals of Cancer Genomics</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className='col-span-2 '>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Faculty
                                                </div>
                                                <p className='mt-3 font-muli font-normal text-[20px] text-[#484848]'>Prof. George Church
                                                    Dr. Anguraj, Dr. Aruna Korlimarla
                                                    & Dr. Amit Dutt</p>

                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

            <div className="mt-[28px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Module 2</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>4 Hours 45 Mins</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>2 Weeks</span></div>
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-6 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-4'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>Introduction to Next Generation Sequencing (NGS) and its application in Cancer Genomics</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className='col-span-2 '>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Faculty
                                                </div>
                                                <p className='mt-3 font-muli font-normal text-[20px] text-[#484848]'>Dr. Pratik Chandrani, Dr. Vidya Veldore, Dr. Anuradha Chougule</p>

                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

            <div className="mt-[28px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Module 3</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>5 Hours 25 Mins</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>2 Weeks</span></div>
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-6 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-4'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>Comprehensive Genomic Profiling – From Sample To Report.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className='col-span-2 '>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Faculty
                                                </div>
                                                <p className='mt-3 font-muli font-normal text-[20px] text-[#484848]'>Dr. Prasenjit Das, Richa Malhotra, Dr. Vidya Veldore, Dr. Sewanti Limaye</p>

                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

            <div className="mt-[28px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Module 4</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>40 Mins</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>3 days</span></div>
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-6 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-4'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>How clinical trials are designed in the era of precision oncology.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className='col-span-2 '>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Faculty
                                                </div>
                                                <p className='mt-3 font-muli font-normal text-[20px] text-[#484848]'>Dr. Vineet Govinda Gupta</p>

                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

            <div className="mt-[28px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Module 5</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>5 Hours 15 Mins</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>1 Week and 4 Days</span></div>
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-12 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-12'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>Molecular Tumor Board discussion on Case Studies – Clinical Impact of Precision Medicine in Practice</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

            <div className="mt-[28px]">
                <Disclosure >
                    {({ open }) => (
                        <>
                            <div className='panel-header grid grid-cols-12 bg-[#FAFAFA] py-4 px-6'>
                                <div className='col-span-5'>
                                    <h2 className='font-verdana font-bold text-primary text-[18px]'>Panel Discussion</h2>
                                    <div className='flex mt-4 space-x-3 items-center'>
                                        <MenuIcon className="stroke-primary stroke-1" />
                                        <span className='text-lg text-[#101010] font-montserrat'>LIVE</span>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2 col-span-6'>
                                    {/* <ClockIcon />
                                    <div className='text-[#101010] text-lg'>Total Duration : <span className='font-semibold text-primary'>2 Weeks</span></div> */}
                                </div>
                                <div className='flex justify-end items-center rounded-full col-span-1'>
                                    <Disclosure.Button >
                                        <ChevronDown color='#2B528E' parentClass={`text-primary transition  cursor-pointer ${open ? 'transform rotate-180' : ''
                                            }`} />


                                    </Disclosure.Button>
                                </div>
                            </div>
                            <Transition
                                show={open}
                                enter='transition duration-100 ease-out'
                                enterFrom='transform scale-95 opacity-0'
                                enterTo='transform scale-100 opacity-100'
                                leave='transition duration-75 ease-out'
                                leaveFrom='transform scale-100 opacity-100'
                                leaveTo='transform scale-95 opacity-0'>
                                <Disclosure.Panel>
                                    <div className='panel-body bg-[#FAFAFA] py-5 px-6'>
                                        <div className='lg:grid lg:grid-cols-6 space-y-2 lg:space-y-0'>
                                            <div className='course-detail-section col-span-4'>
                                                <div className='course-detail-title font-bold text-primary font-muli text-xl'>
                                                    Title
                                                </div>
                                                <div className='pl-4 mt-3 w-full'>
                                                    <ol className='list-decimal w-full font-muli font-normal text-[20px] text-[#484848]'>
                                                        <li>Clinical Impact of Precision Medicine in Practice - Pros and Cons</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </Transition>
                        </>
                    )}
                </Disclosure>
            </div>

{/* <p className='mt-5'>Panel Discussion - Clinical Impact of Precision Medicine in Practice - Pros and Cons</p> */}
        </>
    )
}
