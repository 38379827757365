import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



// import required modules
import { Navigation, FreeMode } from "swiper";

export default function TeamSlider({ team }) {
    return (
        <>
            <Swiper navigation={true} freeMode={true} slidesPerView={1}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                }}
                spaceBetween={30} loop={true}
                loopFillGroupWithBlank={true} modules={[Navigation]} className="teams-slider">
                {
                    team.map((member, index) => {

                        return (
                            <SwiperSlide key={index}>
                                <div className='w-[363px] rounded-2xl teams-slider-card mb-4 pb-4 mx-auto h-[485px]'>
                                    <div className='bg-primary rounded-t-2xl rounded-r-2xl'>
                                        <div className='relative overflow-hidden'>
                                            <div className='absolute inset-0'>

                                            </div>

                                            <div className='relative h-12'>

                                            </div>

                                            <div className='relative'>
                                                <div className='absolute inset-0 flex flex-col' aria-hidden='true'>
                                                    <div className='flex-1' />
                                                    <div className='flex-1 w-full bg-white' />
                                                </div>
                                                <div className='flex flex-col items-center px-4 sm:px-6'>
                                                    <div className='relative bg-white w-48 h-48 rounded-full p-2 teams-image'>
                                                        {member.img !== undefined && member.img !== null && member.img !== '' && (
                                                            <img src={member.img} className='w-full h-full rounded-full' />
                                                        )}
                                                        
                                                    </div>
                                                    <div className='relative h-2 w-full'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='team-card-details text-center mt-[42px]'>
                                        <h5 className='font-montserrat font-semibold text-[#292929] text-[24px]'>{member.name}</h5>
                                        <div className='font-muli text-[16px] leading-[25.8px] text-[#393939] w-6/6 mx-auto mt-[17px] mx-[15px]'>{member.desc}</div>
                                        {member.extra !== undefined && member.extra !== null && member.extra !== '' && (
                                            <div className='font-muli text-[17px] leading-[30.8px] text-[#393939] w-6/6 mx-auto mx-[5px]'>{member.extra}</div>
                                        )}                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                        )

                    })
                }


            </Swiper>
        </>
    );
}
