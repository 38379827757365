import React from 'react';

import OverlayImgTop from '../../../images/overlays/overlay_img_top.svg'
import OverlayImgBtm from '../../../images/overlays/overlay_img_btm.svg'

export default function Overlays({ showTop = true, showBtm = true }) {
    return (
        <div className='absolute inset-0 w-full h-full overflow-hidden'>
            {showTop ? <img src={OverlayImgBtm} className='absolute top-0 left-0 opacity-80' /> : ""}
            {showBtm ? <img src={OverlayImgTop} className='absolute right-0 bottom-0 opacity-80' /> : ''}

        </div>
    );
}
